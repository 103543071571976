// import Popper from 'popper.js';
import 'bootstrap';

import Turbolinks from 'turbolinks';
Turbolinks.start();

import debounced from 'debounced';
debounced.initialize();

import Rails from 'rails-ujs';
Rails.start();

import ReactOnRails from 'react-on-rails';
import './obs_style';

const callback = () => {
  $('.product-col-clickable').click(function (event) {
    event.stopImmediatePropagation();
    window.location = $(this).find('.product-link').attr('href');
  });

  $('[data-toggle="popover"]').popover();

  $('input[name=size_id]').change(function () {
    let newVal = this.value;

    let url = new URL(window.location.href);
    url.searchParams.set('size_id', newVal);

    Turbolinks.visit(url.toString());
  });

  window.disabled_products.forEach((id) => {
    $('#product-' + id).hide();
  });
};

document.addEventListener('turbolinks:load', callback);

import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import Checkout from '../src/checkout/Checkout';
import PayPalWarning from '../src/PayPalWarning';

ReactOnRails.register({
  Checkout,
  PayPalWarning,
});
